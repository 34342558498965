import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Nasze usługi | Poznaj nasze usługi w Drive & Thrive Driving School
			</title>
			<meta name={"description"} content={"Przygotuj się na wszechstronne wrażenia z jazdy!"} />
			<meta property={"og:title"} content={"Nasze usługi | Poznaj nasze usługi w Drive & Thrive Driving School"} />
			<meta property={"og:description"} content={"Przygotuj się na wszechstronne wrażenia z jazdy!"} />
			<meta property={"og:image"} content={"https://lovixen.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://lovixen.com/img/4762232.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://lovixen.com/img/4762232.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://lovixen.com/img/4762232.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://lovixen.com/img/4762232.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://lovixen.com/img/4762232.png"} />
			<meta name={"msapplication-TileImage"} content={"https://lovixen.com/img/4762232.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Nasza oferta
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				W Drive & Thrive Driving School zapewniamy szeroki wachlarz usług zaprojektowanych z myślą o każdym typie ucznia. Od początkujących po zaawansowanych kierowców, nasze programy są dostosowane tak, aby zapewnić każdemu uczniowi osobistą uwagę i ustrukturyzowany program nauczania, którego potrzebują, aby odnieść sukces.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Skontaktuj się z nami
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://lovixen.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section padding="0 0 0 0" quarkly-title="Statistics-7">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="40%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://lovixen.com/img/5.jpg"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					padding="40px 0px 0px 90px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 30px 0px" color="--dark" font="normal 700 42px/1.2 --fontFamily-sans">
					Nasze usługi 
					</Text>
					<Box
						min-width="100px"
						margin="30px 0px 30px 0px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						lg-margin="50px 0px 0px 0px"
						md-margin="40px 0px 0px 0px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Lekcje jazdy dla początkujących
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Ustrukturyzowane podejście do nauki: Nasze lekcje dla początkujących mają na celu budowanie pewności siebie i umiejętności od podstaw, z naciskiem na bezpieczeństwo i podstawowe techniki jazdy.
							<br/><br/>
Bezpieczeństwo przede wszystkim: Nacisk na zrozumienie i przestrzeganie przepisów ruchu drogowego.
							<br/><br/>
Podstawy jazdy samochodem: Nauka niezawodnej obsługi i konserwacji pojazdu.

							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Zaawansowane kursy jazdy
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Doskonalenie umiejętności: Dla tych, którzy chcą doskonalić swoje umiejętności jazdy, oferujemy kursy jazdy defensywnej, jazdy nocnej i postępowania w sytuacjach awaryjnych.
							<br/><br/>
Jazda defensywna: Techniki ochrony siebie i innych na drodze.
							<br/><br/>
Jazda nocą: Specjalistyczne praktyki jazdy w warunkach słabego oświetlenia.

							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
							min-height="80px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Specjalistyczne symulacje jazdy
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Realistyczne scenariusze: Wykorzystanie najnowocześniejszych symulatorów w celu przygotowania uczniów do rzeczywistych wyzwań związanych z prowadzeniem pojazdów.
							<br/><br/>
Warunki pogodowe: Szkolenie w deszczu, śniegu i innych warunkach pogodowych.
							<br/><br/>
Reagowanie w sytuacjach awaryjnych: Pewne radzenie sobie z nagłymi sytuacjami na drodze.

							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="80px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 35px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Przygotowanie do testu drogowego
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Test-Ready Training: Kompleksowe programy przygotowujące do zdawania testów na prawo jazdy z pewnością siebie.
							<br/><br/>
Testy próbne: Realistyczne scenariusze testowe zmniejszające niepokój i poprawiające wydajność.
							<br/><br/>
Informacje zwrotne i doskonalenie: Szczegółowe informacje zwrotne od instruktorów w celu poprawy konkretnych umiejętności.

							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
							min-height="80px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Elastyczne opcje planowania
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Dogodne terminy: Oferujemy różne opcje planowania, aby zapewnić, że nauka jazdy wygodnie pasuje do Twojego życia.
							<br/><br/>
Zajęcia weekendowe: Idealne dla studentów i pracujących profesjonalistów.
							<br/><br/>
Sesje wieczorne: Świetne rozwiązanie dla tych, którzy preferują późny harmonogram.

							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="80px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 35px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Różnorodność pojazdów
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Szeroki wybór pojazdów: Od samochodów kompaktowych po SUV-y - naucz się jeździć pojazdem, który najlepiej odpowiada Twoim potrzebom.
							<br/><br/>
Manualna i automatyczna skrzynia biegów: Opcje dotyczące obu typów skrzyń biegów.
							<br/><br/>
Najnowsze modele: Szkolenie w najnowszych modelach pojazdów z nowoczesnymi funkcjami.

							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px" quarkly-title="Advantages/Features-24">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					lg-width="100%"
				>
					Gotowy, aby rozpocząć swoją przygodę z jazdą?
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Nasz zespół z przyjemnością oferuje spersonalizowane wskazówki, które pomogą Ci poruszać się po ścieżce nauki w Drive & Thrive. Aby uzyskać bardziej szczegółowe informacje na temat naszych usług i znaleźć najlepszy program dla siebie, skontaktuj się z nami lub odwiedź naszą szkołę.
						<br/><br/>
Pozwól nam posadzić Cię za kierownicą, abyś odniósł sukces!

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});